import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./textSliderComponent.scss";
import Observer from "react-intersection-observer";
import { gsap, Power2, Power3 } from "gsap";
import prevIcon from "../../../images/icons/prev.svg";
import nextIcon from "../../../images/icons/next.svg";
import MButton from "@material-ui/core/Button";

const TextSliderComponent = ({ slice, onDownload }) => {
  const slides = useMemo(() => slice.items, [slice.items]);
  const [index, setIndex] = useState(0);
  const [wait, setWait] = useState(false);

  const sliderRef = useRef(null);
  const slideTextRef = useRef(null);
  const slideImageRef = useRef(null);

  useEffect(() => {
    setIndex(0);
    gsap.set(sliderRef.current, { opacity: 0 });
  }, []);

  const slidePrevious = useCallback(() => {
    if (wait) return;
    setWait(true);
    let newIndex = index - 1;

    if (newIndex < 0) {
      newIndex += slides.length;
    }

    gsap.to(slideTextRef.current, {
      x: "20px",
      opacity: 0,
      duration: 0.3,
      ease: Power2.easeOut,
      onComplete: () => {
        gsap.to(slideTextRef.current, {
          x: "-20px",
          duration: 0.05,
          ease: Power2.easeOut,
          onComplete: () => {
            gsap.to(slideTextRef.current, {
              x: "0%",
              opacity: 1,
              duration: 0.3,
              ease: Power2.easeOut,
              onComplete: () => {
                setWait(false);
              },
            });
          },
        });
      },
    });
    gsap.to(slideImageRef.current, {
      x: "20px",
      opacity: 0,
      duration: 0.3,
      ease: Power2.easeOut,
      onComplete: () => {
        setIndex(newIndex);
        gsap.to(slideImageRef.current, {
          x: "-20px",
          duration: 0.05,
          ease: Power2.easeOut,
          onComplete: () => {
            gsap.to(slideImageRef.current, {
              x: "0%",
              opacity: 1,
              duration: 0.3,
              ease: Power2.easeOut,
              onComplete: () => {
                setWait(false);
              },
            });
          },
        });
      },
    });
  }, [wait, index, slides.length]);

  const slideNext = useCallback(() => {
    if (wait) return;
    setWait(true);
    let newIndex = (index + 1) % slides.length;

    gsap.to(slideTextRef.current, {
      x: "-20px",
      opacity: 0,
      duration: 0.3,
      ease: Power2.easeOut,
      onComplete: () => {
        gsap.to(slideTextRef.current, {
          x: "20px",
          duration: 0.05,
          ease: Power2.easeOut,
          onComplete: () => {
            gsap.to(slideTextRef.current, {
              x: "0%",
              opacity: 1,
              duration: 0.3,
              ease: Power2.easeOut,
              onComplete: () => {
                setWait(false);
              },
            });
          },
        });
      },
    });
    gsap.to(slideImageRef.current, {
      x: "-20px",
      opacity: 0,
      duration: 0.3,
      ease: Power2.easeOut,
      onComplete: () => {
        setIndex(newIndex);
        gsap.to(slideImageRef.current, {
          x: "20px",
          duration: 0.05,
          ease: Power2.easeOut,
          onComplete: () => {
            gsap.to(slideImageRef.current, {
              x: "0%",
              opacity: 1,
              duration: 0.3,
              ease: Power2.easeOut,
              onComplete: () => {
                setWait(false);
              },
            });
          },
        });
      },
    });
  }, [wait, index, slides.length]);

  const handleContentVisibility = useCallback((inView) => {
    if (inView) {
      gsap.to(sliderRef.current, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4,
      });
    }
  }, []);

  const slideList = useMemo(
    () =>
      slides[index].list.raw.map((item, i) => (
        <li key={i} className="brochuresPage_ourTip_list_item">
          {item.text}
        </li>
      )),
    [slides, index]
  );

  return (
    <section id="textSliderComponent" className="textSliderComponent">
      <Observer
        threshold="0.1"
        onChange={handleContentVisibility}
        className="wrapper"
      >
        <div className="textSliderComponent__cont">
          <div className="textSliderComponent__cont_content">
            <div
              ref={sliderRef}
              className="textSliderComponent__cont_content_sliderCont"
            >
              <div className="textSliderComponent__cont_content_sliderCont_slide">
                <div className="brochuresPage_ourTip_wrap" ref={slideTextRef}>
                  <div>
                    <h2 className="brochuresPage_ourTip_title">
                      {slides[index].title.text}
                    </h2>
                    <div>
                      <p className="brochuresPage_ourTip_description">
                        {slides[index].description.text}
                      </p>
                    </div>
                    <ul className="brochuresPage_ourTip_list">{slideList}</ul>
                    <div>
                      <p className="brochuresPage_ourTip_description">
                        {slides[index].description_second.text}
                      </p>
                    </div>
                    <MButton
                      className="brochuresPage_ourTip_btn"
                      onClick={() => onDownload(slides[index])}
                    >
                      {slides[index].btn_label.text}
                    </MButton>
                  </div>
                </div>

                {slides.length > 1 && (
                  <div className="textSliderComponent__cont_content_sliderCont_slide_text_controls">
                    <div className="textSliderComponent__cont_content_sliderCont_slide_text_controls_buttons">
                      <img
                        className="textSliderComponent__cont_content_sliderCont_slide_text_controls_buttons--prev"
                        src={prevIcon}
                        alt="smartwealth previous icon"
                        style={{ opacity: index === 0 ? 0.3 : 1 }}
                        onClick={slidePrevious}
                      />
                      <img
                        className="textSliderComponent__cont_content_sliderCont_slide_text_controls_buttons--next"
                        src={nextIcon}
                        alt="smartwealth next icon"
                        style={{
                          opacity: index + 1 === slides.length ? 0.3 : 1,
                        }}
                        onClick={slideNext}
                      />
                    </div>
                    <div className="textSliderComponent__cont_content_sliderCont_slide_text_controls_dotCont">
                      {slides.map((_, i) => (
                        <div
                          key={i}
                          className="textSliderComponent__cont_content_sliderCont_slide_text_controls_dotCont_dot"
                          style={{
                            background: index == i ? "white" : "#17181D",
                          }}
                        ></div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="brochuresPage_ourTip_book" ref={slideImageRef}>
              <img src={slides[index].image.url} />
            </div>
          </div>
        </div>
      </Observer>
    </section>
  );
};

export default TextSliderComponent;
