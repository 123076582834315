import React, { forwardRef } from "react";
import MButton from "@material-ui/core/Button";

const BrochureItem = forwardRef(({ item, onDownload }, ref) => {

  return (
    <div className="brochure_item" ref={ref}>
      <div className="brochure_item_image">
        <img src={item.image.url} alt={item.title.text} />
      </div>
      <h4 className="brochure_item_title">{item.title.text}</h4>
      <p className="brochure_item_description">{item.description.text}</p>
      <MButton className="brochure_item_btn" onClick={onDownload}>
        {item.btn_label.text}
      </MButton>
    </div>
  );
});

export default BrochureItem;
