import React, { useState, useEffect, useCallback, useContext } from "react";
import $ from "jquery";
import "./brochureDetail.scss";
import BrochureForm from "./BrochureForm";
import { gsap, Power2 } from "gsap/all";
import { isTablet } from "react-device-detect";
import hideIcon from "@images/icons/hide.svg";
import closeIcon from "@images/icons/dark-close.svg";
import { Provider } from "react-redux";
import store from "./store";
import { brochuresAPI } from "@api/index.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { toast } from "react-toastify";

const BrochureDetail = ({ item, onClose }) => {
  const [countries, setCountries] = useState([]);
  const [isError, setIsError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const context = useContext(ContextProviderComponent);
  const translations = context.translationsDataForForm.primary;
  const [timerId, setTimerId] = useState(null);
  useEffect(() => {
    (async () => {
      const countriesList = await brochuresAPI.getCountries();
      setCountries(countriesList);
    })();
  }, []);

  useEffect(() => {
    showDetail();
    disableScroll();

    if (isTablet && window.innerWidth > window.innerHeight) {
      $(".brochureDetail").css("position", "fixed");
    }

    $(".brochureDetail_close, .brochureDetail .backBtn").on("click", () =>
      hideDetail()
    );

    $(".brochureDetail_close, .brochureDetail .closeBtn").on("click", () =>
      hideDetail()
    );

    return () => {
      $(".brochureDetail_close, .brochureDetail .backBtn").off("click", () =>
        hideDetail()
      );
      $(".brochureDetail_close, .brochureDetail .closeBtn").off("click", () =>
        hideDetail()
      );
      enableScroll();

      if (timerId) clearTimeout(timerId);
    };
  }, [timerId]);

  const onSubmit = useCallback(
    async (values) => {
      setIsError(false);

      const {
        salutation,
        name,
        company,
        jobTitle,
        country,
        email,
        phone,
        phone_code,
      } = values;

      const data = {
        salutation: salutation,
        name: name,
        email: email,
        company: company,
        jobTitle: jobTitle,
        country: country,
        brochureName: item?.brochurename?.text,
        phone: {
          code: phone_code,
          number: phone,
        },
      };
      try {
        const token = await executeRecaptcha("BROCHURE");
        if (!token) {
          console.error("Recaptcha verification failed");
          return;
        }
        data.captchaToken = token;
        await brochuresAPI.sendBrochure(data);

        setTimerId(
          setTimeout(() => {
            hideDetail();
          }, 3000)
        );
        toast.success(translations.submit_success.text, {
          position: "top-right",
        });
      } catch (e) {
        setIsError(true);
      }
    },
    [executeRecaptcha, item?.brochurename?.text]
  );

  return (
    <div className="brochureDetail">
      <div className="brochureDetail_cont">
        {/* LEFT SIDE */}
        <div className="brochureDetail_cont_left">
          <div className="brochureDetail_cont_left_header">
            <div className="brochureDetail_cont_left_header_close">
              <img
                src={hideIcon}
                className="backBtn"
                alt="smartwealth close icon"
              ></img>
              <span>{translations.back.text}</span>
            </div>
          </div>
          <div className="brochureDetail_cont_left_body">
            <div className="brochureDetail_cont_left_body_title">
              {item.title.text}
            </div>
            <p className="brochureDetail_cont_left_body_desc">
              {item.description.text}
            </p>
            <div className="brochureDetail_cont_left_body_image">
              <img src={item.image.url} alt={item.title.text} />
            </div>
          </div>
        </div>

        {/* RIGHT SIDE */}
        <div className="brochureDetail_cont_right">
          <div className="brochureDetail_cont_left_header_close">
            <img
              src={closeIcon}
              className="closeBtn"
              alt="smartwealth close icon"
            ></img>
          </div>

          <div className="brochureDetail_cont_right_body">
            <div className="brochureDetail_cont_right_body_title">
              {translations.title.text}
            </div>
            <div className="brochureDetail_cont_right_body_form">
              <Provider store={store}>
                <BrochureForm
                  onSubmit={onSubmit}
                  countries={countries}
                  translations={translations}
                />
              </Provider>
            </div>
            {isError && (
              <p className="brochureDetail_error">
                {translations.submit_error.text}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function enableScroll() {
    $("html, body").css("overflow", "");
  }

  function disableScroll() {
    $("html, body").css("overflow", "hidden");
  }

  function showDetail() {
    gsap.to($("header"), {
      y: "100%",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onStart: () => {
        disableScroll();
        $("#fp-nav").fadeOut();
      },
      onComplete: () => {
        $(".brochureDetail").fadeIn();
        gsap.to($(".brochureDetail_cont"), 0.4, {
          x: "0px",
          ease: Power2.easeOut,
          delay: 0.2,
        });
      },
    });
  }

  function hideDetail() {
    gsap.to($(".brochureDetail_cont"), 0.4, {
      x: "-100%",
      ease: Power2.easeOut,
      onComplete: () => {
        $(".brochureDetail").fadeOut();
        gsap.to($("header"), {
          y: "0%",
          opacity: 1,
          ease: Power2.easeOut,
          onComplete: () => {
            onClose();
            enableScroll();
            $("#fp-nav").fadeIn();
          },
        });
      },
    });
  }
};

export default BrochureDetail;
