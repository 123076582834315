import React, { useEffect, useState, useCallback } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import TextSliderComponent from "@slices/TextSliderComponent/TextSliderComponent";
import BrochureDetail from "./brochureDetail/BrochureDetail";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const captchaKey = process.env.RECAPTCHA_KEY;

export const OutTip = ({ slice }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [brochureData, setBrochureData] = useState(null);

  useEffect(() => {
    initGSAP();
  }, []);

  const handleContentVisibility = useCallback((inView, entry) => {
    if (inView) {
      animateContent();
    }
  }, []);

  const handleBrochureFromChild = useCallback((brochureData) => {
    setBrochureData(brochureData);
    setShowDetail(true);
  }, [setBrochureData,setShowDetail]);

  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper"
    >
      <div className="brochuresPage_ourTip">
        <div className="container">
          <div className="wrapper_text">
            <span className="brochuresPage_ourTip_span">Our tip</span>
            <TextSliderComponent
              slice={slice}
              onDownload={handleBrochureFromChild}
            />
          </div>
        </div>
      </div>
      {showDetail && (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
          <BrochureDetail item={brochureData} onClose={() => setShowDetail(false)} />
        </GoogleReCaptchaProvider>
      )}
    </Observer>
  );

  function initGSAP() {
    gsap.set([".wrapper_text"], {
      opacity: 0,
      y: 100,
    });
  }

  function animateContent() {
    gsap.to(".wrapper_text", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
  }
};

export default OutTip;
