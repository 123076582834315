import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import BrochureItem from "./BrochureItem";
import Observer from "react-intersection-observer";
import { gsap, Power3 } from "gsap";
import prevIcon from "@images/icons/prev.svg";
import nextIcon from "@images/icons/next.svg";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import BrochureDetail from "./brochureDetail/BrochureDetail";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const captchaKey = process.env.RECAPTCHA_KEY;

export default function BrochuresList({ items }) {
  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState(null);

  const sliderRef = useRef(null);
  const [slidersCount, setSlidersCount] = useState(3);
  const [isShowCarousel, setIsShowCarousel] = useState(false);

  useEffect(() => {
    setSlidersCount(isMobile ? 1 : 3);
    setIsShowCarousel(slidersCount < items.length);
    initGSAP();
  }, [isMobile, slidersCount, items.length]);

  const handleContentVisibility = useCallback((inView, entry) => {
    if (inView) {
      animateContent();
    }
  }, []);

  const slideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, [sliderRef]);

  const slidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, [sliderRef]);

  const handleDataFromChild = useCallback(
    (childData) => {
      setData(childData);
      setShowDetail(true);
    },
    [setData, setShowDetail]
  );

  const settings = useMemo(
    () => ({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: items.length >= 3 ? 3 : items.length,
      slidesToScroll: items.length >= 3 ? 3 : items.length,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [items.length]
  );

  const listBrochures = useMemo(() => {
    return items.map((item, index) => (
      <BrochureItem
        onDownload={() => handleDataFromChild(item)}
        key={item.title.text + index}
        item={item}
      />
    ));
  }, [items, handleDataFromChild]);

  const renderSlider = useMemo(() => {
    return (
      <Slider ref={sliderRef} {...settings} className="brochures_list carousel">
        {listBrochures}
      </Slider>
    );
  }, [settings, listBrochures]);

  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper"
    >
      <div
        className={`brochuresPage_brochures ${
          isShowCarousel ? "slider-container" : ""
        }`}
      >
        {isShowCarousel ? (
          renderSlider
        ) : (
          <div className="brochures_list indents">{listBrochures}</div>
        )}
        {isShowCarousel && (
          <div className="brochuresListSlider__cont_sliderCont_controls">
            <img
              className="brochuresListSlider__cont_sliderCont_controls--prev"
              alt="smartwealth previous arrow icon"
              src={prevIcon}
              onClick={slidePrev}
            ></img>
            <img
              alt="smartwealth next arrow icon"
              className="brochuresListSlider__cont_sliderCont_controls--next"
              src={nextIcon}
              onClick={slideNext}
            ></img>
          </div>
        )}
      </div>
      {showDetail && (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
          <BrochureDetail item={data} onClose={() => setShowDetail(false)} />
        </GoogleReCaptchaProvider>
      )}
    </Observer>
  );

  function initGSAP() {
    gsap.set(
      [
        ".brochure_item_image",
        ".brochure_item_title",
        ".brochure_item_description",
        ".brochure_item_btn",
      ],
      {
        y: 100,
        opacity: 0,
      }
    );
  }

  function animateContent() {
    gsap.to(".brochure_item_image", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
    gsap.to(".brochure_item_title", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.5,
    });
    gsap.to(".brochure_item_description", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.6,
    });
    gsap.to(".brochure_item_btn", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.7,
    });
  }
}
