import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Field,
  reduxForm,
  formValueSelector,
  updateSyncErrors,
} from "redux-form";
import { connect } from "react-redux";
import {
  TextField,
  Checkbox,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  debounce,
} from "@material-ui/core";
import validate from "./validate";
import { brochuresAPI } from "@api/index.js";

const renderTextField = ({
  input,
  label,
  placeholder,
  helperText,
  meta: { touched, error },
  ...custom
}) => (
  <>
    <TextField
      label={label}
      placeholder={placeholder}
      error={touched && !!error}
      helperText={touched && error ? error : helperText}
      {...input}
      {...custom}
    />
  </>
);

const renderCheckbox = ({ input, label, meta: { touched, error } }) => (
  <>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
          error={touched && !!error}
        />
      }
      label={label}
    />
    {touched && error && <FormHelperText error>{error}</FormHelperText>}
  </>
);

const renderPhoneField = ({
  input,
  label,
  placeholder,
  helperText,
  meta: { touched, error },
  ...custom
}) => (
  <>
    <TextField
      label={label}
      placeholder={placeholder}
      error={touched && !!error}
      helperText={touched && error ? error : helperText}
      {...input}
      {...custom}
    />
  </>
);

const renderCountriesSelect = ({
  input,
  label,
  countries,
  meta: { touched, error },
}) => {
  return (
    <FormControl error={touched && !!error}>
      <InputLabel>{label}</InputLabel>
      <Select {...input}>
        {countries?.map((country) => (
          <MenuItem key={country.countryCd} value={country.countryCd}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const renderPhoneCodeSelect = ({
  input,
  label,
  meta: { touched, error },
  countries,
}) => {
  const phoneCodes = [
    ...new Set(countries?.map((country) => country.dialingCode)),
  ];
  return (
    <FormControl error={touched && !!error}>
      <InputLabel>{label}</InputLabel>
      <Select {...input}>
        {phoneCodes.map((code) => (
          <MenuItem key={code} value={code}>
            {code}
          </MenuItem>
        ))}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const renderGenderRadio = ({
  options,
  input,
  label,
  meta: { touched, error },
}) => (
  <FormControl component="fieldset">
    <RadioGroup {...input} aria-label="salutation" name="salutation">
      {options.map(({ label, value }) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<Radio />}
          label={label}
        />
      ))}
    </RadioGroup>
    {touched && error && <FormHelperText error>{error}</FormHelperText>}
  </FormControl>
);

const BrochureForm = (props) => {
  const {
    handleSubmit,
    submitting,
    dispatch,
    countries,
    translations,
    change,
  } = props;
  const [phone, setPhone] = useState("");
  const [phone_code, setPhoneCode] = useState("");

  const salutationsOptions = useMemo(
    () => [
      { label: translations.male.text, value: "MR" },
      { label: translations.female.text, value: "MRS" },
    ],
    [translations]
  );

  const validatePhone = useCallback(
    debounce(async (phone_code, phone) => {
      try {
        if (!phone || !phone_code) return;

        const fullNumber = phone_code + phone;
        await brochuresAPI.validatePhoneNumber(fullNumber);
      } catch (e) {
        dispatch(
          updateSyncErrors("brochureForm", {
            phone: e.response.data.errorMessage,
          })
        );
      }
    }, 1000),
    [dispatch]
  );

  useEffect(() => {
    validatePhone(phone_code, phone);
  }, [phone_code, phone, validatePhone]);

  const onChangeCountry = useCallback(
    (e) => {
      const selectedCountry = countries.find(
        (c) => c.countryCd === e.target.value
      );
      if (selectedCountry) {
        const phone_code = selectedCountry.dialingCode;
        setPhoneCode(phone_code);
      change("phone_code", phone_code);
      }
    },
    [countries, change]
  );

  const onChangePhoneCode = useCallback((e) => {
    const code = e.target.value;
    setPhoneCode(code);
  }, [setPhoneCode]);

  const onChangePhone = useCallback((e) => {
    const v = e.target.value;
    setPhone(v || "");
  }, [setPhone]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="brochure_form_field">
        <Field
          name="salutation"
          component={renderGenderRadio}
          options={salutationsOptions}
        />
      </div>
      <div className="brochure_form_field">
        <Field
          name="name"
          component={renderTextField}
          label={translations.name.text}
        />
      </div>

      <div className="brochure_form_field">
        <Field
          name="company"
          component={renderTextField}
          label="Company (optional)"
        />
      </div>

      <div className="brochure_form_field">
        <Field
          name="jobTitle"
          component={renderTextField}
          label={translations.jobtitle.text}
        />
      </div>

      <div className="brochure_form_field">
        <Field
          name="country"
          component={renderCountriesSelect}
          label={translations.country.text}
          countries={countries}
          onChange={onChangeCountry}
        />
      </div>

      <div className="brochure_form_field">
        <Field
          name="email"
          component={renderTextField}
          label={translations.email.text}
        />
      </div>

      <div className="brochure_form_field_phone">
        <Field
          name="phone_code"
          component={renderPhoneCodeSelect}
          label={translations.phone_code.text}
          countries={countries}
          onChange={onChangePhoneCode}
        />
        <Field
          onChange={onChangePhone}
          name="phone"
          component={renderPhoneField}
          label={translations.phone.text}
        />
      </div>

      <div className="brochure_form_field">
        <Field
          name="confirm"
          component={renderCheckbox}
          label={translations.confirm.text}
        />
      </div>

      <div className="brochure_form_field">
        <Field
          name="subscribe"
          component={renderCheckbox}
          label={translations.subscribe.text}
        />
      </div>

      <button
        type="submit"
        className="brochure_form_btn_submit"
        disabled={submitting}
      >
        <span>Download</span>
      </button>
    </form>
  );
};

const selector = formValueSelector("brochureForm");

const mapStateToProps = (state) => {
  const phone = selector(state, "phone");
  const phone_code = selector(state, "phone_code");

  return {
    phone,
    phone_code,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "brochureForm",
    validate,
  })(BrochureForm)
);
